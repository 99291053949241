import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import Quagga from 'quagga'

export default class extends Controller {
  static values = {
    field: { type: String, default: 'barcode_data' },
    facilityId: { type: Number, default: null },
    workType: { type: String, default: null },
    serviceType: { type: String, default: null },
    newWork: { type: Boolean, default: false },
    url: { type: String, default: '/reporting/inventories/find' }
  }

  static targets = ['cameraAccess']

  connect () {
    this.#initQuagga()
    Quagga.onDetected(this.#onDetect.bind(this))

    // Set a timeout for barcode reading
    // this.timeout = setTimeout(() => {
    //   Quagga.stop()
    //   this.#post()
    // }, 10000)

    // Quagga.onProcessed((result) => {
    //   // If a barcode is read successfully, clear the timeout
    //   if (result && result.codeResult) {
    //     clearTimeout(this.timeout)
    //   }
    // })
  }

  disconnect () {
    try {
      Quagga.offDetected()
      Quagga.offProcessed()
      Quagga.stop()
    } catch (error) {
      console.error('Error disconnecting Quagga:', error)
    }
  }

  #initQuagga () {
    Quagga.init({
      halfSample: true,
      patchSize: 'x-small',
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        constraints: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          facingMode: 'environment',
          aspectRatio: { min: 1, max: 2 }
        },
        numOfWorkers: navigator.hardwareConcurrency,
        target: this.element
      },
      decoder: {
        readers: ['code_128_reader', 'code_39_reader']
      }
    }, (err) => {
      if (err) {
        // clearTimeout(this.timeout)
        return this.cameraAccessTarget.classList.remove('hidden')
      }
      Quagga.initialized = true
      Quagga.start()
    })
  }

  #onDetect (result) {
    this.results = this.results || []

    if (!result.codeResult) {
      console.error('Failed to read barcode')
      return
    }

    const { code } = result.codeResult
    this.results.push(code)

    // Find the most frequent result
    if (this.results.length >= 4) {
      const code = this.#mostFrequent(this.results)
      Quagga.stop()
      this.#post(code)
    }
  }

  #post (code = '') {
    post(this.urlValue, {
      body: this.#body(code),
      responseKind: 'turbo-stream'
    })
  }

  #body (code) {
    let data = {
      barcode: code,
      field: this.fieldValue,
      work_type: this.workTypeValue,
      service_type: this.serviceTypeValue,
      new_work: this.newWorkValue
    }

    data = this.facilityIdValue ? { ...data, facility_id: this.facilityIdValue } : data

    return data
  }

  #mostFrequent (array) {
    const frequencyMap = {}
    let maxCount = 0
    let mostFrequentElement

    for (const element of array) {
      frequencyMap[element] = (frequencyMap[element] || 0) + 1

      if (frequencyMap[element] > maxCount) {
        maxCount = frequencyMap[element]
        mostFrequentElement = element
      }
    }

    return mostFrequentElement
  }
}
