import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static values = {
    newUrl: String
  }

  static targets = ['select']

  connect () {
    this.dispatch('onConnect')

    if (this.element.dataset.choice !== 'active') {
      this.#setup()
    }

    document.addEventListener('turbo:morph', this.#setup.bind(this))
  }

  disconnect () {
    try {
      this.choices.destroy()
    } catch (error) {
    }
    document.removeEventListener('turbo:morph', this.#setup.bind(this))
  }

  #setup () {
    this.choices = new Choices(this.selectTarget, this.#getOptions())

    if (this.newUrlValue) {
      this.#appendNewLink()
    }
  }

  #getOptions () {
    const { searchEnabled, removeItemButton } = this.element?.dataset
    return {
      // searchChoices: !this.remoteSearchUrlValue,
      addItems: true,
      allowHTML: true,
      delimiter: ',',
      duplicateItemsAllowed: false,
      maxItemCount: -1,
      removeItemButton,
      removeItems: true,
      searchEnabled: searchEnabled !== 'false', // default: true
      searchFloor: 1,
      searchResultLimit: 10,
      shouldSort: false,
      silent: true,
      fuseOptions: {
        threshold: 0.2
      }
    }
  }

  #appendNewLink () {
    const dropdownList = this.element.querySelector('.choices__list--dropdown')

    if (dropdownList && !dropdownList.querySelector('.choices__item--new')) {
      const linkElement = `<a href="${this.newUrlValue}" class="choices__item choices__item--new" data-turbo-frame="modal">+ New item</a>`
      dropdownList.insertAdjacentHTML('beforeend', linkElement)
    }
  }

  // #remoteSearch () {
  //   if (this.remoteSearchUrlValue) {
  //     const lookupDelay = 300
  //     let lookupTimeout = null

  //     this.element.addEventListener('search', (event) => {
  //       clearTimeout(lookupTimeout)

  //       lookupTimeout = setTimeout(() => {
  //         this.choices.clearChoices()
  //         this.choices.setChoices(async () => {
  //           const response = await fetch(
  //             this.remoteSearchUrlValue + event.detail.value
  //           )
  //           const data = await response.json()
  //           return data
  //         }).then(() => {
  //           event.target.parentNode.querySelector('input').focus()
  //         })
  //       }, lookupDelay)
  //     })
  //   }
  // }
}
